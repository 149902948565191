import clsx from 'clsx'

export enum TypoVariant {
  Huge = 'Huge',
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4',
  H5 = 'H5',
  H6 = 'H6',
  P = 'P',
  PBig = 'PBig',
  Caption = 'Caption',
}

type ValidElement = keyof Pick<
  HTMLElementTagNameMap,
  'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'div' | 'span'
>

const semanticMap = {
  [TypoVariant.Huge]: 'h1',
  [TypoVariant.H1]: 'h1',
  [TypoVariant.H2]: 'h2',
  [TypoVariant.H3]: 'h3',
  [TypoVariant.H4]: 'h4',
  [TypoVariant.H5]: 'h5',
  [TypoVariant.H6]: 'h6',
  [TypoVariant.P]: 'p',
  [TypoVariant.PBig]: 'p',
  [TypoVariant.Caption]: 'p',
} as const satisfies Record<TypoVariant, ValidElement>

const variantSizeMap = {
  [TypoVariant.Huge]: clsx('text-[3rem] md:text-[6rem] 2xl:text-[10rem]'),
  [TypoVariant.H1]: clsx('text-5xl tracking-tight md:text-6xl'),
  [TypoVariant.H2]: clsx('text-3xl tracking-tight lg:text-4xl'),
  [TypoVariant.H3]: clsx('text-xl lg:text-2xl'),
  [TypoVariant.H4]: clsx('text-lg tracking-tight lg:text-xl'),
  [TypoVariant.H5]: clsx('text-lg'),
  [TypoVariant.H6]: '',
  [TypoVariant.P]: '',
  [TypoVariant.PBig]: 'text-lg lg:text-xl',
  [TypoVariant.Caption]: clsx('text-sm'),
} as const satisfies Record<TypoVariant, string>

const variantWeightMap = {
  [TypoVariant.Huge]: 'font-black',
  [TypoVariant.H1]: 'font-bold',
  [TypoVariant.H2]: 'font-semibold',
  [TypoVariant.H3]: 'font-medium',
  [TypoVariant.H4]: 'font-medium',
  [TypoVariant.H5]: 'font-medium',
  [TypoVariant.H6]: 'font-medium',
  [TypoVariant.P]: '',
  [TypoVariant.PBig]: '',
  [TypoVariant.Caption]: '',
} as const satisfies Record<TypoVariant, string>

export interface TypoProps extends React.HTMLAttributes<HTMLElement> {
  /**
   * Determines visual style of the typography, with a mapped semantic render element to match. Override the semantic element using the `as` prop.
   */
  variant?: TypoVariant
  /**
   * Determines the semantic element, does not affect visual style.
   */
  as?: ValidElement
  /**
   * Tailwind class name which overrides the default font weight class name.
   */
  weightClass?: string
  underline?: boolean
}

export const Typo: React.FC<TypoProps> = ({
  as,
  weightClass,
  variant = TypoVariant.P,
  underline,
  children,
  className,
  ...props
}) => {
  const Tag = as ?? semanticMap[variant]

  const variantClasses = variantSizeMap[variant]
  const boldClasses = weightClass ?? variantWeightMap[variant]

  const underlineClasses = underline ? clsx('border-b pb-2') : ''

  return (
    <Tag
      className={clsx(variantClasses, boldClasses, underlineClasses, className)}
      {...props}
    >
      {children}
    </Tag>
  )
}
