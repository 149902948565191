'use client'

import {
  Close,
  Content,
  Description,
  Overlay,
  Portal,
  Root,
  Title,
  Trigger,
} from '@radix-ui/react-dialog'
import { IconX } from '@tabler/icons-react'
import clsx from 'clsx'
import * as React from 'react'

const Sheet = Root

const SheetTrigger = Trigger

const SheetClose = Close

const SheetPortal = Portal

const SheetOverlay = React.forwardRef<
  React.ElementRef<typeof Overlay>,
  React.ComponentPropsWithoutRef<typeof Overlay>
>(({ className, ...props }, ref) => (
  <Overlay
    className={clsx(
      'fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      className,
    )}
    {...props}
    ref={ref}
  />
))
SheetOverlay.displayName = Overlay.displayName

type Side = 'top' | 'bottom' | 'left' | 'right'

const sideClasses: Record<Side, string> = {
  top: 'inset-x-0 top-0 rounded-b-lg data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top',
  bottom:
    'inset-x-0 bottom-0 data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom',
  left: 'inset-y-0 left-0 h-full w-3/4 data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-sm',
  right:
    'inset-y-0 right-0 h-full w-3/4 data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-sm',
}

interface SheetContentProps
  extends React.ComponentPropsWithoutRef<typeof Content> {
  side?: Side
}

const SheetContent = React.forwardRef<
  React.ElementRef<typeof Content>,
  SheetContentProps
>(({ side = 'top', className, children, ...props }, ref) => (
  <SheetPortal>
    <SheetOverlay />
    <Content
      ref={ref}
      className={clsx(
        'fixed z-50 gap-4 bg-secondary transition ease-in-out data-[state=closed]:duration-200 data-[state=open]:duration-300 data-[state=open]:animate-in data-[state=closed]:animate-out',
        sideClasses[side],
        className,
      )}
      {...props}
    >
      {children}
      <Close className="absolute right-4 top-4 rounded-sm focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-secondary">
        <IconX className="h-12 w-12 p-2 md:h-14 md:w-14" />
        <span className="sr-only">Close</span>
      </Close>
    </Content>
  </SheetPortal>
))
SheetContent.displayName = Content.displayName

const SheetHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>): React.ReactNode => (
  <div
    className={clsx('flex flex-col text-center sm:text-left', className)}
    {...props}
  />
)
SheetHeader.displayName = 'SheetHeader'

const SheetFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>): React.ReactNode => (
  <div
    className={clsx(
      'flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2',
      className,
    )}
    {...props}
  />
)
SheetFooter.displayName = 'SheetFooter'

const SheetTitle = React.forwardRef<
  React.ElementRef<typeof Title>,
  React.ComponentPropsWithoutRef<typeof Title>
>(({ className, ...props }, ref) => (
  <Title ref={ref} className={className} {...props} />
))
SheetTitle.displayName = Title.displayName

const SheetDescription = React.forwardRef<
  React.ElementRef<typeof Description>,
  React.ComponentPropsWithoutRef<typeof Description>
>(({ className, ...props }, ref) => (
  <Description ref={ref} className={className} {...props} />
))
SheetDescription.displayName = Description.displayName

export {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetOverlay,
  SheetPortal,
  SheetTitle,
  SheetTrigger,
}
