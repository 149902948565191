'use client'

import { IconMenu2 } from '@tabler/icons-react'
import { useState } from 'react'

import { NavItem, type NavItemProps } from '~/components/nav-item'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '~/components/sheet'

interface MobileNavProps {
  navItems: NavItemProps[]
  i18n: {
    open: string
    title: string
    description: string
    navAriaLabel: string
  }
}

export const HeaderMobileNav: React.FC<MobileNavProps> = ({
  navItems,
  i18n,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger className="ml-auto lg:hidden" title={i18n.open}>
        <span className="sr-only">{i18n.open}</span>
        <IconMenu2 className="h-12 w-12 p-2 md:h-14 md:w-14" />
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle className="sr-only">{i18n.title}</SheetTitle>
          <SheetDescription className="sr-only">
            {i18n.description}
          </SheetDescription>
          <nav role="navigation" aria-label={i18n.navAriaLabel}>
            <ul className="my-4 flex flex-col">
              {navItems.map((item) => (
                <li key={item.name}>
                  <NavItem
                    className="flex w-full p-4 text-xl ring-inset"
                    {...item}
                    onClick={() => {
                      setIsOpen(false)
                    }}
                  />
                </li>
              ))}
            </ul>
          </nav>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  )
}
