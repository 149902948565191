'use client'
import clsx from 'clsx'
import { useLocale } from 'next-intl'

import { Link, usePathname } from '~/lib/navigation'
import type { LocaleData } from '~/lib/types'

interface LangProps {
  langs: LocaleData[]
  className?: string
}

export const LangSelect: React.FC<LangProps> = ({ langs, className }) => {
  const locale = useLocale()
  const pathname = usePathname()

  return (
    <nav className={className}>
      <ul className="flex lg:flex-col">
        {langs.map((lang) => (
          <li key={lang.code}>
            <Link
              href={pathname}
              locale={lang.code}
              className="group flex items-center gap-2 px-4 py-2.5 text-sm lg:py-[2.5px]"
            >
              <div
                className={clsx(
                  'ml-auto h-5 w-5 rounded-full',
                  locale === lang.code
                    ? 'bg-current'
                    : 'border-2 border-current bg-transparent group-hover:opacity-60',
                )}
              />
              <div
                lang={lang.code}
                title={lang.name}
                className="min-w-14 no-underline"
              >
                {lang.name}
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}
