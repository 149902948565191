import { createSharedPathnamesNavigation } from 'next-intl/navigation'
import type { LocalePrefix } from 'next-intl/routing'

export type ValidLocale = (typeof locales)[number]

export const locales = ['en', 'ja'] as const
export const defaultLocale = 'ja'
export const localePrefix = 'always' as const satisfies LocalePrefix<
  typeof locales
>

export const { Link, redirect, usePathname, useRouter } =
  createSharedPathnamesNavigation({ locales, localePrefix })

export const internalLink = {
  index: '/',
  menu: '/menu',
  concept: '/concept',
  charityKitchen: '/charity-kitchen',
  faq: '/faq',
  contact: '/contact',
  contactSuccess: '/contact-success',
} as const

export type InternalLink = (typeof internalLink)[keyof typeof internalLink]

export const email = 'uchiwarabe@gmail.com'

export const externalLink = {
  line: 'https://page.line.me/683lakqi',
  instagram: 'https://www.instagram.com/_u/uchiwarabe',
  facebook: 'https://www.facebook.com/profile.php?id=100050556589494',
  email: `mailto:${email}`,
  telephone: 'tel:+0368061308',
  googleMaps: 'https://maps.app.goo.gl/2RCpbR7Mu76JVvSd8',
  uchimanabe: 'https://uchimanabe.com/',
} as const

export const baseUrl = 'https://uchiwarabe.com/'
